<template>
    <div>
        <div class="page-holder">
            <div class="top-nav">
                <div class="back-text" @click="$router.go(-1)">
                    <i class="fa fa-arrow-left"></i> <span>Back</span>
                </div>
                <div class="right-nav d-none">
                    <!-- <div class="notification-border">
                        <button class="nav-link">
                        <img src="@/assets/images/notification.svg" alt="" />
                        <span class="notification-counter">0</span>
                        </button>
                    </div> -->
                    <img src="@/assets/profileImage.jpg" alt="profile">
                    <div>
                        <i class="fa fa-chevron-down"></i>
                    </div>
                </div>
            </div>
            <div class="page-grid container-fluid">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3">
                            <!-- It has a duplicate down for mobile view, this shows only on desktop and larger screens -->
                            <div class="d-none d-sm-block d-sm-none d-md-block">
                                <div class="promo-details">
                                    <h5>Q3 Smart Promo</h5>
                                    <hr>
                                    <h6>Take part in our SMART promo to win awesome rewards!</h6>
                                    <h6 class="mb-4">Promo period: Aug 1 - Sept 29, 2024</h6>
                                    <!-- <button class="btn">Join Promo</button> -->
                                    <h6>More Details</h6>
                                    <div class="side-dropdown">
                                        <div @click="toggleDropdown(1)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Milestone breakdown</span>
                                                <i class="" :class="[isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options" :key="option"><span class="dot"></span> {{ option }}</li>
                                                <li style="color: #db4242;">Note: PP = Promo Points</li>
                                            </ul>
                                        </div>

                                        <div @click="toggleDropdown(2)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Rewards breakdown</span>
                                                <i class="" :class="[isOpen2 ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen2" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options2" :key="option"><span class="dot"></span>{{ option }}</li>
                                            </ul>
                                        </div>

                                        <div @click="toggleDropdown(3)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Key points</span>
                                                <i class="" :class="[isOpen3 ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen3" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options3" :key="option"><span class="dot"></span>{{ option }}</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <!-- progress starts -->
                            <div v-if="!loading" class="progress-holder">
                                <div class="container">
                                    <h6 class="promo-progress-text">Q3 Smart Promo Progress</h6>
                                    <div class="row">
                                        <div class="col-md-1">
                                            <img class="pp-img" style="width: 66px" src="../assets/icons/profile-promo.svg" alt="profile">
                                        </div>
                                        <div class="col-md-11">
                                            <div class="row">
                                                <div class="col-md-4 mb-3">
                                                    <div class="row" style="flex-wrap: nowrap;">
                                                        <div class="col-md-6">
                                                            <p>My position</p>
                                                            <p>{{ promoTable.loggedinuser_position || 0}}</p>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p>My points</p>
                                                            <p>{{ promoTable.loggedinuser_point || 0 }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row progres-details">
                                                <div class="col-md-4 progress-options">
                                                    <div class="row" style="flex-wrap: nowrap;">
                                                        <div class="col-md-6">
                                                            <p>Current milestone</p>
                                                            <small>{{ promoTable.loggedinuser_milestone || 0}}</small>
                                                        </div>
                                                        <div class="col-md-6" v-if="promoTable">
                                                            <p>Current reward</p>
                                                            <small>₦{{ Number(promoTable.loggedinuser_reward).toLocaleString() || 0}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4  progress-options">
                                                    <div class="row" style="flex-wrap: nowrap;">
                                                        <div class="col-md-6">
                                                            <p>Next milestone</p>
                                                            <small>{{ promoTable.loggedinuser_next_milestone || 0}}</small>
                                                        </div>
                                                        <div class="col-md-6" v-if="promoTable">
                                                            <p>Next reward</p>
                                                            <small>₦{{ Number(promoTable.loggedinuser_next_reward).toLocaleString() || 0}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Progress ends -->
                            <h3 class="leader-board-text" v-if="!loading">Leader Board</h3>
                            <div class="row">
                                <div class="col-md-4"  v-for="(item, index) in promoTable.leader_board" :key="index">
                                    <div class="top-card">
                                        <div class="mb-3">
                                            <img src="@/assets/profileImage.jpg" alt="profile">
                                            <span>{{ item.fullname }}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center card-option">
                                            <div class="">
                                                <h6>position</h6>
                                                <h5>{{ item.position || 0}}</h5>
                                            </div>
                                            <div class="">
                                                <h6>Mile stones</h6>
                                                <h5>{{ item.milestone || 0 }}</h5>
                                            </div>
                                            <div class="">
                                                <h6>Reward</h6>
                                                <h5>₦{{ Number(item.reward).toLocaleString() || 0 }}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        
                            <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
                            <div  v-else class="leader-holder">
                        
                                <div class="leaderboard-table table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Position</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Points</th>
                                                <th scope="col">No. of SA</th>
                                                <th scope="col">No. Landlord</th>
                                                <th scope="col">Mile stone</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Reward</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in promoTable.full_table" :key="index">
                                                <td>{{ item.position  || "-"}}</td>
                                                <td>{{ item.fullname || "-"}}</td>
                                                <td>{{ item.points || "-"}}</td>
                                                <td>{{ item.no_of_smart_associate || "-"}}</td>
                                                <td>{{ item.no_of_landlord || "-"}}</td>
                                                <td>{{ item.milestone || "-"}}</td>
                                                <td>{{ item.location || "-"}}</td>
                                                <td v-if="item">₦{{ Number(item.reward).toLocaleString() || "-"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="empty text-center mt-4 " v-if="promoTable.full_table.length == 0 && !loading">No Promo Records</div> -->

                            <!-- There is is duplicste up, tis shows only on mobile -->
                            <div class="mt-4 d-md-none d-lg-block d-lg-none d-xl-none">
                                <div class="promo-details">
                                    <h5>Q3 Smart Promo</h5>
                                    <hr>
                                    <h6>Take part in our SMART promo to win awesome rewards!</h6>
                                    <h6 class="mb-4">Promo period: Aug 1 - Sept 29, 2024</h6>
                                    <!-- <button class="btn">Join Promo</button> -->
                                    <h6>More Details</h6>
                                    <div class="side-dropdown">
                                        <div @click="toggleDropdown(1)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Milestone breakdown</span>
                                                <i class="" :class="[isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options" :key="option"><span class="dot"></span>{{ option }}</li>
                                                <li style="color: #db4242;">Note: PP = Promo Points</li>
                                            </ul>
                                        </div>

                                        <div @click="toggleDropdown(2)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Rewards breakdown</span>
                                                <i class="" :class="[isOpen2 ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen2" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options2" :key="option"><span class="dot"></span>{{ option }}</li>
                                            </ul>
                                        </div>

                                        <div @click="toggleDropdown(3)" class="menu-holder">
                                            <div class="menu-header-holder">
                                                <span>Key points</span>
                                                <i class="" :class="[isOpen3 ? 'fa fa-chevron-up' : 'fa fa-chevron-down']"></i>
                                            </div>
                                            <ul v-if="isOpen3" class="menu-opions slideInOut">
                                                <hr>
                                                <li v-for="option in options3" :key="option"><span class="dot"></span> {{ option }}</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import { getPromoTableDetails } from "../services/promo";
 import Loader from './Loader';
 export default {
    components: {
      Loader,
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      isOpen2: false,
      isOpen3: false,
      isVissible: false,
      promoTable: "",
      options: ["M1: 20PP", "M2: 40PP", "M3: 120PP", "M4: 140PP", "M5: 160PP", "M6: 200PP", "M7 = M6 >= +1PP And must have the highest points"],
      options2: ["R1: ₦50,000", "R2: ₦50,000", "R3: ₦300,000", "R4: ₦100,000", "R5: ₦100,000", "R6: All expense paid trip to Lagos for two (2) nights", "R7: ₦1,000,000"],
      options3: ["Rewards are sequential (R2 after R1)", "Reward requires milestone completion", "Rewards are auto paid to smart account"]
    };
  },
  methods: {
    showHideAmout() {
        return this.isVissible = !this.isVissible;
    },
    toggleDropdown(type) {
      if (type === 1) {
        this.isOpen2 = false;
        this.isOpen3 = false;
        return this.isOpen = !this.isOpen;
      }
      if (type === 2) {
        this.isOpen = false;
        this.isOpen3 = false;
        return this.isOpen2 = !this.isOpen2;
      }
      if (type === 3) {
        this.isOpen = false;
        this.isOpen2 = false;
        return this.isOpen3 = !this.isOpen3;
      }
    },
    async fetchPromoDetails() {
        this.loading = true;
        await getPromoTableDetails().then(data => {
            // console.log("promo details", data)
            this.loading = false;
            if (data.success) {
                this.promoTable = data;
            } else {
                this.$toast.error(data.error);
            }
        }).catch(() => {
            this.loading = false;
            this.$toast.error("please check your network and refresh the page");
        });
    },
  },
  mounted() {
    this.fetchPromoDetails();
  }

};
</script>

<style lang="scss" scoped>


.top-nav {
    padding: 16px 32px;
    border-bottom: 1px solid #E9E3D2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.back-text {
    cursor: pointer;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #2B3352;
    span {
        margin-left: 10px;
    }
}

.right-nav {
    display: flex;
    justify-content: right;
    align-items: center;
    img {
        margin-right: 10px;
        width: 38px;
        border-radius: 50%;
    }
}

.notification-border{
  background: #F4F7FF;
  border: 1px solid #E7E7E7;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  
  padding-bottom: 0px;
    padding-top: 4px;

}
.notification-counter {
  font-weight: 50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: red;
  color: #fff;
  font-size: 5px;
  line-height: 5px;
  height: 6px;
  width: 6px;
  vertical-align: top;
  margin-top: -19px;
  margin-left: 6px;
  
}

.page-holder {
    background: #FCF4DD;
    height: 100%;
    min-height: 100vh;
    // padding-top: 50px;
    padding-bottom: 40px;
}

.page-grid {
    padding-top: 30px;
}

.promo-progress-text {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
    margin-bottom: 20px;
}

.progress-holder {
    background: #F4ECD4;
    padding: 16px;
    border-radius: 10px;
    // margin-top: 15px;
    margin-bottom: 20px;

    p {
        font-family: Lato;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #2B3352;
        margin-bottom: 5px !important;
    }
    small {
        font-family: Lato;
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: #2B3352;
    }

}

.promo-details {
    background: #242424;
    border-radius: 20px;
    padding: 20px 24px;
    // margin-top: 5rem;
    h5 {
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #FFFFFF;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #FFFFFF;
    }
    hr {
        border-top: 1px solid #ffffff;
    }
    button {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #242424;
        background: #ffffff;
        border-radius: 8px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        box-shadow: none;
        outline: 0;
    }
}

.menu-holder {
    background: #ffffff;
    border-radius: 8px;
    margin: 15px 0;
    // padding: 10px 5px 10px;
}

.menu-header-holder {
    background: #ffffff;
    color: #2B3352;
    border-radius: 8px;
    padding: 10px 12px;
    // border: 1px solid #D0D5DD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #2B3352;

}

.menu-opions {
    padding: 0 0 10px 0;
    list-style-type: none;
    // transition: all .5s ease-in-out;
    hr {
        border-top: 1px solid #000000;
        margin-top: 0;
        margin-bottom: 5px;
    }
    li {
        padding: 8px 10px;
        // cursor: pointer;
        font-family: Lato;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
        display: flex;
        align-items: center;
    }
    li:hover {
        background: #edeef1;
        border-radius: 5px;
        // margin: 0 20px;
    }
}

// @keyframes slide-in {
//     from {
//       transform: translateY(-100%);
//     }
//     to {
//       transform: translateY(0%);
//     }
//   }


// .slideInOut {
//     animation: slide-in 1000ms;
// }

.leader-board-text {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #242424;
    margin-bottom: 15px;
}

.top-card {
    background: #F4ECD4;
    border-radius: 10px;
    padding: 12px;
    img {
        width: 13%;
        border-radius: 50px;
    }
    span {
        margin-left: 10px;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
        text-transform: uppercase;
    }

    h6 {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #2B3352;
    }
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
    }
}

.leader-holder {
    margin-top: 30px;
}

.leaderboard-table {
    // margin-top: 30px;
    border: 1px solid #BCB5A2;
    border-radius: 8px;
}

.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #BCB5A2;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #F4ECD4;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #2B3352
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2B3352
}
.dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 15px;
    background: rgb(39, 163, 39);
}

@media screen and (max-width: 599px) { 
    .promo-details {
        margin-top: 0;
    }
    .leader-holder {
        margin-top: 30px;
    }
    .top-card {
        margin-bottom: 15px;
    }
    .progress-holder {
        margin-bottom: 15px;
    }
    .progress-options {
        margin-bottom: 15px;
    }
    .pp-img {
        display: flex;
        margin: auto;
        padding-bottom: 15px;
    }
    .promo-progress-text {
        text-align: center;
    }
}   



</style>